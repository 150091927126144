.ReactConfirmDialog .react-confirm-dialog-bg { /*Modal Background*/
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	background: rgba(0,0,0,0.35);
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	z-index: 1000;
}
.ReactConfirmDialog .react-confirm-dialog-content {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	margin: auto;
	height: 200px;
	width: 280px;
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3NyYy9Db25maXJtLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSwrQ0FBK0Msb0JBQW9CO0NBQ2xFLGdCQUFnQjtDQUNoQixhQUFhO0NBQ2IsWUFBWTtDQUNaLFNBQVM7Q0FDVCxVQUFVO0NBQ1YsNkJBQTZCO0NBQzdCLDBCQUFrQjtJQUFsQix1QkFBa0I7S0FBbEIsc0JBQWtCO1NBQWxCLGtCQUFrQjtDQUNsQixjQUFjO0NBQ2Q7QUFDRDtDQUNDLG1CQUFtQjtDQUNuQixTQUFTO0NBQ1QsV0FBVztDQUNYLFlBQVk7Q0FDWixVQUFVO0NBQ1YsYUFBYTtDQUNiLGNBQWM7Q0FDZCxhQUFhO0NBQ2IsY0FBYztDQUNkLG1CQUFtQjtDQUNuQix1QkFBdUI7Q0FDdkIiLCJmaWxlIjoiQ29uZmlybS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuUmVhY3RDb25maXJtRGlhbG9nIC5yZWFjdC1jb25maXJtLWRpYWxvZy1iZyB7IC8qTW9kYWwgQmFja2dyb3VuZCovXHJcblx0cG9zaXRpb246IGZpeGVkO1xyXG5cdGhlaWdodDogMTAwJTtcclxuXHR3aWR0aDogMTAwJTtcclxuXHR0b3A6IDBweDtcclxuXHRsZWZ0OiAwcHg7XHJcblx0YmFja2dyb3VuZDogcmdiYSgwLDAsMCwwLjM1KTtcclxuXHR1c2VyLXNlbGVjdDogbm9uZTtcclxuXHR6LWluZGV4OiAxMDAwO1xyXG59XHJcbi5SZWFjdENvbmZpcm1EaWFsb2cgLnJlYWN0LWNvbmZpcm0tZGlhbG9nLWNvbnRlbnQge1xyXG5cdHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuXHR0b3A6IDBweDtcclxuXHRyaWdodDogMHB4O1xyXG5cdGJvdHRvbTogMHB4O1xyXG5cdGxlZnQ6IDBweDtcclxuXHRtYXJnaW46IGF1dG87XHJcblx0aGVpZ2h0OiAyMDBweDtcclxuXHR3aWR0aDogMjgwcHg7XHJcblx0cGFkZGluZzogMjBweDtcclxuXHRib3JkZXItcmFkaXVzOiA1cHg7XHJcblx0YmFja2dyb3VuZC1jb2xvcjogI2ZmZjtcclxufSJdfQ== */