.ReactConfirmDialog .react-confirm-dialog-bg { /*Modal Background*/
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	background: rgba(0,0,0,0.35);
	user-select: none;
	z-index: 1000;
}
.ReactConfirmDialog .react-confirm-dialog-content {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	margin: auto;
	height: 200px;
	width: 280px;
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
}